//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isSbAnyLinkFilled } from '~/utils/helpers';

export default {
  name: 'UiProjectsTableItem',
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      initSwiper: false,
      swiperOptions: {
        slidesPerView: 1,
        watchOverflow: true,
        autoplay: {
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 0,
      },
    }
  },
  computed: {
    content() {
      return this.item.content;
    },
    year() {
      const date = this.content.date;

      return date ? new Date(date).getFullYear() : '';
    },
    isDisabled() {
      return !!this.state;
    },
    state() {
      return this.content.state;
    },
    slides() {
      return !this.state && this.content.preview;
    },
    previewExist() {
      return (this.slides?.length || this.state) && this.$device.isDesktop;
    },
    link() {
      const redirectLink = this.content.redirect;
      if (redirectLink && isSbAnyLinkFilled(redirectLink)) return redirectLink;

      return {
        linktype: 'story',
        cached_url: this.item.full_slug
      };
    },
  },
  methods: {
    handleTippyShow() {
      this.initSwiper = true;
    },
    handleTippyHide() {
      this.initSwiper = false;
    },
  },
};
